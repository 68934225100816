export default {
    app: {
        drawerTitle: 'Nadzorni Center',
        title: 'Nadzorni Center - Mangart',
        shortTitle: 'NC - Mangart',
        icon: 'language',
        appKey: 'gg0QZpqE36'
    },

    user: {
        id: null,
        name: null,
        email: null,
        role: null,
        permissions: null,
        access_token: null,
        last_seen: null,
        table: []
    },

    drawer: null,
    loader: false,
    progress: false,
    loaderText: '',
    toolbarTitle: '',
    search: '',
    searchFieldState: false,
    mobileSearch: false,

    snackbar: {
        display: false,
        y: 'bottom',
        x: 'left',
        mode: '',
        timeout: 7000,
        text: 'Hello, I\'m a snackbar'
    },

    access_log: [],
    currentRoute: null,

    table: [],
    stanja: [],
    nacini: []
}