import { post, get, destroy, put, API_ENDPOINT } from '@/helpers/api'

export default {
    ROUTE_NAME (context, routeName) {
        context.commit('SET_CURRENT_ROUTE', routeName)
    },

    LOGIN (context, credentials) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'auth/user-login', credentials) 
                .then(response => {
                    context.commit('SET_USER', response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
  
    LOGOUT (context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'auth/logout')
            .then(response => {
                context.commit('SET_USER', null)
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
  
    PASSWORD_RESET_REQUEST (context, email) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'password/create', email)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PASSWORD_RESET (context, credentials) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'password/reset', credentials)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PASSWORD_RESET_TOKEN_FIND(context, token) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'password/find/' + token)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
  
    RESTRICTION_TYPES (context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'mangart/restriction-types')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },


    
    CREATE_RFID_USER (context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'mangart/rfid-users/create', data )
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    GET_RFID_USER (context, userId) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'mangart/rfid-users/' + userId)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    UPDATE_RFID_USER(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'mangart/rfid-users/' + data.user_uuid + '/update', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    DELETE_RFID_USER (context, id) {
        return new Promise((resolve, reject) => {
            destroy(API_ENDPOINT + 'mangart/rfid-users/' + id + '/delete')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PARAMETERS_DATA (context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'mangart/parameters-data')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    GET_SETTINGS(context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'mangart/settings')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    SAVE_SETTINGS(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'mangart/settings/save', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PUBLISH (context, payload) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'mangart/mqtt-publish', payload)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    GET_PARKING_DATA(context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'mangart/parking-occupancy')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    UPDATE_PARKING_OCCUPANCY(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'mangart/parking-occupancy', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    TABLE (context, user_id) {
        return new Promise((resolve, reject) => {
          get(API_ENDPOINT + 'users/' + user_id + '/table')
          .then(response => {
            /*context.commit('setTable', response.data.data.table)
            context.commit('setStanja', response.data.data.stanja)
            context.commit('setNacini', response.data.data.nacini)*/
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
        })
      },
  
    TABLA (context, id_tabla) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'table/' + id_tabla)
            .then(response => {
            if(response.data.data) {
                context.commit('setTabla', response.data.data)
            }

            resolve(response)
            })
            .catch(error => {
            reject(error)
            })
        })
    },

    SEMAFOR (context, id_semafor) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'semaforji/' + id_semafor)
            .then(response => {
            context.commit('setSemafor', response.data.data)
            resolve(response)
            })
            .catch(error => {
            reject(error)
            })
        })
    },

    UPDATE_SEMAFOR (context, semafor) {
        return new Promise( (resolve, reject) => {
            put(API_ENDPOINT + 'semaforji/' + semafor.id_semafor + '/update', semafor)
            .then(response => {
            resolve(response)
            })
            .catch(error => {
            reject(error)
            })
        })
    },

    UPDATE_TABLA (context, tabla) {
        return new Promise( (resolve, reject) => {
            put(API_ENDPOINT + 'table/' + tabla.id_tabla + '/update', tabla)
            .then(response => {
            resolve(response)
            })
            .catch(error => {
            reject(error)
            })
        })
    },

    PARKING_GATES (context) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'mangart/parking-gates/devices')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PARKING_GATES_PARAMETERS_DATA (context) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'mangart/parking-gates/parameters-data')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    CONVERT_RFID_NR (context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'utilities/rfid-nr', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    CONVERT_RFID_NR_2 (context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'utilities/rfid-nr/2', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    RFID_NR_SEARCH (context, nr) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'utilities/rfid-nr/' + nr)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    RFID_NR_SEARCH_2 (context, nr) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'utilities/rfid-nr/2/' + nr)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    DEVICES (context) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'mangart/parking-gates/devices')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },


}