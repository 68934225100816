<template>
    <v-container grid-list-xl :fluid="fluid" style="">
        <v-layout row wrap :align-center="boxedLayout" :justify-center="boxedLayout" :fill-height="boxedLayout">
            <v-flex v-if="routeName != 'traffic_lights'" xs12 sm12 :md12="!boxedLayout" :lg12="!boxedLayout" :md10="boxedLayout" :lg10="boxedLayout" :xl8="boxedLayout">
                <v-toolbar class="elevation-1" dense>
                    <v-toolbar-title><span  class="font-weight-regular body-1">{{ title }}</span></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <div v-show="displayBreadCrumbs">
                        <v-breadcrumbs :items="breadcrumbsArray" divider="-" dense>
                            <template  v-slot:item="props">
                                <v-breadcrumbs-item v-if="props.item.text == 'dashboard'">
                                    <v-icon small>mdi-home</v-icon>
                                </v-breadcrumbs-item>
                                <v-breadcrumbs-item v-else :to="props.item.href">
                                    {{ props.item.text }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </div>
                    <div v-show="!displayBreadCrumbs">
                        <v-icon>{{ icon }}</v-icon>
                    </div>
                </v-toolbar>
            </v-flex>

            <v-flex v-else  xs12 sm12 :md12="!boxedLayout" :lg12="!boxedLayout" :md8="boxedLayout" :lg6="boxedLayout" :xl4="boxedLayout">
                <v-toolbar class="elevation-1" dense>
                    <v-toolbar-title><span  class="font-weight-regular body-1">{{ title }}</span></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <div v-show="displayBreadCrumbs">
                        <v-breadcrumbs :items="breadcrumbsArray" divider="-" dense>
                            <template  v-slot:item="props">
                                <v-breadcrumbs-item v-if="props.item.text == 'dashboard'">
                                    <v-icon small>mdi-home</v-icon>
                                </v-breadcrumbs-item>
                                <v-breadcrumbs-item v-else :to="props.item.href">
                                    {{ props.item.text }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </div>
                    <div v-show="!displayBreadCrumbs">
                        <v-icon>{{ icon }}</v-icon>
                    </div>
                </v-toolbar>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { Menu } from '@/helpers/menu';

export default {
    name: 'app-page-header',
    data: () => ({
        title: '',
        icon: '',
        routeName: null,
        fluid: false,
        breadcrumbsArray: []   
    }),

    watch: {

    },

    computed: {
        displayBreadCrumbs() {
            if(this.$vuetify.breakpoint.mdAndUp) {
                return true
            }

            return false
        },

        boxedLayout() {

            let routeName = this.$store.getters.currentRouteName
            let vm = this;
            //window.console.log(routeName)

            if(routeName == 'settings' || routeName == 'traffic_lights') {
                return true;
            }

            if(routeName == 'add_user' || routeName == 'parking_barriers' || routeName == 'parking_barriers_management' ) {
                return true;
            }

            if(routeName == 'edit_user') {
                vm.title = "Uredi uporabnika"
                vm.icon = "mdi-account-circle"
                return true
            }

            if(routeName == 'parking_occupancy') {
                return true
            }

            if(routeName == 'users_list') {
                vm.fluid = false
                return false
            }


            return false
        }
    },

    methods: {
        breadcrumbs: function () {
            let breadcrumbs = [];
            let homeCrumb = {
                text: 'dashboard',
                disabled: false
            };

            breadcrumbs.push(homeCrumb);
            Menu.forEach(item => {
                if (item.items) {
                let child =  item.items.find(i => {
                    return i.name === this.$route.name;
                })
                if (child) {
                    breadcrumbs.push({ text: item.title, disabled: false, href: item.path });
                    breadcrumbs.push({ text: child.title, disabled: false, href: child.path });
                    this.title = child.title;
                    this.icon = child.icon
                } 

                //if(item.hasOwnProperty('items')) {
                    if(Object.prototype.hasOwnProperty.call(item, 'items')) {
                    item.items.forEach(item_item => {
                        //if(item_item.hasOwnProperty('items')) {
                            if(Object.prototype.hasOwnProperty.call(item_item, 'items')) {
                            item_item.items.forEach(item_item_item => {
                                if(item_item_item.name === this.$route.name) {
                                    breadcrumbs.push({ text: item.title, disabled: false, href: item.path });
                                    breadcrumbs.push({ text: item_item.title, disabled: false, href: item_item.path });
                                    breadcrumbs.push({ text: item_item_item.title, disabled: false, href: item_item_item.path });
                                    this.title = item_item_item.title;
                                    this.icon = item_item_item.icon
                                    return
                                }
                            })
                        }
                    })
                }

                } else {
                if (item.name === this.$route.name) {
                    this.title = item.title;
                    this.icon = item.icon
                    breadcrumbs.push({ text: item.title, disabled: false, href: item.path});
                }
                }
            });

            this.breadcrumbsArray = breadcrumbs
        },

        

        goToHomePage() {
            return this.$router.push({
                name: 'dashboard'
            })
        }
    },

    created() {
        this.routeName = this.$route.name
        //window.console.log(this.routeName)

        this.breadcrumbs()
    },

    mounted() {

    },

    destroyed() {

    }
}
</script>

<style>
    .theme--light.v-breadcrumbs .v-breadcrumbs__item--disabled {
        color: rgba(0, 0, 0, 0.7) !important;
    }
</style>