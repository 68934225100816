import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'


import AppPageHeader from '@/views/layout/AppPageHeader.vue'
import AppLoader from '@/components/Loader.vue'



Vue.component('app-page-header', AppPageHeader)
Vue.component('app-loader', AppLoader)
Vue.use(PerfectScrollbar)



// Some middleware to help us ensure the user is authenticated.
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth) && (!store.getters.user.access_token || store.getters.user.access_token === '')) {
    return next({
      path: '/prijava',
      query: { redirect: to.fullPath }
    })
  } else if ( (to.name == 'login' || to.name == 'passwordResetRequest' || to.name == 'passwordReset') && store.state.user.access_token) {
    return router.push({
      name: 'parking_occupancy'
    })
  } else if(to.path == '/') {
    return router.push({
      //name: 'parking_occupancy'
      name: 'dashboard'
    })
  } else {
      return next()
  }
})

// Check local storage to handle refreshes
if (window.localStorage) {
  var user = JSON.parse(window.localStorage.getItem('gg0QZpqE36')) || null
  if ( (user && store.state.user.id !== user.id) && (user.access_token && store.state.user.access_token !== user.access_token) ) {
    store.commit('SET_USER', user)
  }
}

//event bus
export const EventBus = new Vue();

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
